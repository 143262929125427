import React from "react"
import { Link, } from "react-router-dom";
import { useNavigate } from 'react-router';
import ThemeImages from "../../themes/imgconstant";
const Footer = () => {
    return (
        <footer className="foot-bg" >
            <div className="container">
                <div className="foot-main">
                    <h4>Contact Us </h4>
                    <img src={ThemeImages.aboutLine} />
                    <p>We’d love to hear from you</p>
                    <a onClick={() => {
                        try {
                            window.location.href = "mailto:t1dfriendsinfo@gmail.com";
                        } catch (error) {
                            console.log('#####', error);
                        }
                    }}>t1dfriendsinfo@gmail.com</a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;