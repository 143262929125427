import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

import ThemeImages from "../../themes/imgconstant";

const Header = (props) => {
  const navigate = useNavigate();
  const { dataPoint, show } = props;
  const anc = window.location.href.split("/")[3];

  return (
    <div className="header">
      <div className="header-wrapper">
        <div className="container">
          <div className="upper_header">
            <a className="navbar-brand" href="/">
              <img src={ThemeImages.logo} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
