import logo from './logo.svg';
import './App.css';
import './css/bootstrap.css'
import './css/style.css'
import './css/responsive.css'
import 'antd/dist/antd.css';
import RoutesNew from './Route/Routes';




function App() {
  return (
    <div className="App">
   
     <RoutesNew/>

    </div>
  );
}

export default App;
