const ThemeImages = {
  logo: require('../img/logo-white.svg').default,
  group:require('../img/group-image.png'),
  line:require('../img/line.svg').default,
  apple:require('../img/apple.svg').default,
  play:require('../img/play-store.svg').default,
  frame:require('../img/new-frame.svg').default,
  aboutLine:require('../img/about-line.svg').default,
  iphone:require('../img/iphone-pro.svg').default,
  insta:require('../img/instagram.svg').default,
  copyright:require('../img/Copyright.svg').default,
}
export default ThemeImages;