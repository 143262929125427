import React, { useEffect, useState } from "react";
import ThemeImages from "../../themes/imgconstant";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../common/header";
import Footer from "../common/footer";

import { Link } from "react-router-dom";

const appLinks = {
  androidAppLink: 'https://play.google.com/store/apps/details?id=com.t1d',
  iosAppLink: 'https://apps.apple.com/us/app/t1d-friends/id6449973465',
  instagram: 'https://www.instagram.com/t1dfriendsapp/'
}

const LandingPage = () => {
  useEffect(() => {
    document.title = "T1D";
  }, []);
  const [selectedTab, setSelectedTab] = useState(1);

  return (
    <div className="homeWrapper">
      <div className="bg-col">
        <div className="bg-img">
          <section className="td-one-sec">
            <div className="world-map">
              <div className="container">
                <Header />
                <div className="row top-spacing ">
                  <div className=" col-lg-6 ">
                    <div className="banner-spacing ">

                      <h4>meet new people!</h4>

                      <div className="new-friends">
                        <h1>MAKE NEW FRIENDS</h1>
                        <img className="line-image" src={ThemeImages.line} />
                      </div>
                      <div className="sub-text">
                        <p>
                          T1D friends is about connecting with others, connecting with people who have a common ground to bond over.
                          You can text and share images with friends and discover new people in your same age range! To start connecting, download the app today!
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="group-image ">
                      <img src={ThemeImages.group} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div></div>

          <section>
            <div className="container">
              <div className="new-frame">
                <img src={ThemeImages.frame} />
              </div>
              <div className="download-app">
                <div className="inner-download-text">
                  <div className="app-now">
                    <h5>
                      Download <br />
                      The App Now
                    </h5>
                  </div>
                  <div className="app-links">
                    <a href={appLinks.iosAppLink} target="_blank"> <img src={ThemeImages.apple} />
                    </a>
                    <a href={appLinks.androidAppLink} target="_blank">  <img src={ThemeImages.play} /></a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row about-top-spacing">
                <div className="col-lg-8">
                  <div className="about-us">
                    <h4>About Us <span> <img src={ThemeImages.aboutLine} /></span></h4>

                    <p>
                      Type 1 Friends by Audax Enterprises, LLC is an app made by someone with Type 1 for others with Type 1. This app allows for people to look though posts from others and to ‘high five’ as well as comment on said posts. You can message and connect with others on this app that are either at the middle, high, and college level so you can find others who share type 1! Once you start chatting with another person you can message them as well as sending them photos, we hope you enjoy the app!
                    </p>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="iphone">
                    <img src={ThemeImages.iphone} />

                  </div>

                </div>
              </div>
            </div>
          </section>

          <Footer />

          <section>
            <div className="copy-right">
              <a href={appLinks.instagram} target="_blank"> <img src={ThemeImages.insta} alt="insta" /> </a>
              <div className="foot-page-link">
                <a href="http://admin.t1dfriends.com/termsAndConditions" target="_blank" >Terms & Conditions </a>
                <a href="http://admin.t1dfriends.com/privacyPolicy" target="_blank" > Privacy Policy </a>

              </div>
              <p>
                <img src={ThemeImages.copyright} />
                2023 Audax Enterprises, LLC.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
